import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ApiRequest, { createLoader } from "./restservice";
import { copyToClipboard, errorToast, successToast } from "./showtoast";
import AnimatedNumber from "./AnimatedNumber";
import { BrowserProvider, Contract } from "ethers";
import { ABI, ContractAddress, TokenABI, TokenContract } from "./contract";
import SponsorTree from "./sponsortree";

export default function Dashboard() {
  let timeouts = [];
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  let { ref: sponsorAddress } = params;
  const [account, setAccount] = useState(null);
  const [showModal, setShowmodal] = useState(false);
  const [contract, setContract] = useState(null);
  const [tokenContract, setTokenContract] = useState(null);
  const [totalDeposit, setTotalDeposit] = useState("0");
  const [registeredUser, setregisteredUser] = useState(false);
  const [totalWithdraw, setTotalWithdraw] = useState("0");
  const [totalWithdrawUSDT, setTotalWithdrawUSDT] = useState("0");
  const [usdtBalance, setusdtBalance] = useState("0");
  const [tokenBalance, setTokenBalance] = useState("0");
  const [newDepositAmount, setNewDepositAmount] = useState("0");
  const [affiliateLink, setAffiliateLink] = useState("");
  const [dailyRoi, setDailyRoi] = useState("0");
  const [rate, setRate] = useState("0");
  const [matchingBonus, setMatchingBonus] = useState("0");
  const [matchingBonusUSDT, setMatchingBonusUSDT] = useState("0");
  const [myTotalDeposit, setMyTotalDeposit] = useState("0");
  const [joinDate, setJoinDate] = useState(new Date().toDateString());
  const [numberOfDeposits, setNumberOfDeposits] = useState("0");
  const [withdrawmode, setwithdrawmode] = useState("USDT");
  const [totalUsers, setTotalUsers] = useState("0");
  // const [teamCount, setTeamCount] = useState("0");
  const [directCount, setDirectCount] = useState("0");
  useEffect(() => {
    let prov = ConnectToInjected();
    if (prov) {
      connectWallet();
      prov.on("accountsChanged", (accounts) => {
        setAccount(accounts[0] || null);
        if (accounts[0]) connectWallet();
      });
      prov.on("chainChanged", () => {
        window.location.reload();
      });
    } else {
      alert("Web3 not detected. Please install MetaMask.");
    }
    return () => {
      timeouts.forEach((timeout) => clearTimeout(timeout));
      timeouts = [];
    };
  }, []);
  useEffect(() => {
    if (account) GetDashboardDetails();
  }, [account]);
  const ConnectToInjected = () => {
    let provider = null;
    if (typeof window.ethereum !== "undefined") {
      provider = window.ethereum;
    } else if (window.web3) {
      provider = window.web3.currentProvider;
    } else if (window.celo) {
      provider = window.celo;
    }
    return provider;
  };
  const connectWallet = async () => {
    let prov = ConnectToInjected();
    if (prov) {
      try {
        let provider = new BrowserProvider(prov);
        let chainid = await prov.request({ method: "eth_chainId" });
        if (chainid !== 56) {
          //97
          try {
            await prov.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: "0x38" }], //"0x38"
            });
          } catch (e) {
            let msg = e.reason
              ? e.reason
              : e.data
              ? e.data.message
              : e.message
              ? e.message
              : e;
            return errorToast(msg);
          }
        }
        await provider.send("eth_requestAccounts", []);
        const signer = await provider.getSigner();
        const contractInstance = new Contract(ContractAddress, ABI, signer);
        const tcontractInstance = new Contract(TokenContract, TokenABI, signer);
        setContract(contractInstance);
        setTokenContract(tcontractInstance);
        setAccount(signer.address);
      } catch (e) {
        let msg = e.reason
          ? e.reason
          : e.data
          ? e.data.message
          : e.message
          ? e.message
          : e;
        errorToast(msg);
      }
    } else {
      alert("Web3 not detected. Please install MetaMask.");
    }
  };

  const GetDashboardDetails = async () => {
    if (!account) {
      errorToast("Web3 not connected");
      return;
    }
    let res = await ApiRequest("GetDashboardDetails.asp", "GET", null, {
      Authorization: account,
    });
    if (res.status === "error") {
      // errorToast(res.message);
      setregisteredUser(false);
      setTotalDeposit(0);
      setTotalWithdraw(0);
      setRate(0);
      setTotalWithdrawUSDT(0);
      setusdtBalance(0);
      setTokenBalance(0);
      setAffiliateLink("");
      setDailyRoi(0);
      setMatchingBonus(0);
      setMatchingBonusUSDT(0);
      setMyTotalDeposit(0);
      setJoinDate("--/--/----");
      setNumberOfDeposits(0);
      setTotalUsers(0);
      // setTeamCount(0);
      setDirectCount(0);

      Swal.fire({
        title: "New User Detected",
        text: "You are a non registered user. Please confirm to register.",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Confirm",
      }).then((result) => {
        if (result.isConfirmed) {
          ActReg();
        }
      });
      return;
    }
    setregisteredUser(true);
    setTotalDeposit(res.totalBusiness);
    setRate(res.tokenrate);
    setTotalWithdraw(res.totalWithdrawn);
    setTotalWithdrawUSDT(res.totalWithdrawnusdt);
    setusdtBalance(res.usdtBalance);
    setTokenBalance(res.tokenBalance);
    setAffiliateLink("https://presale.maximtoken.com/office/?ref=" + account);
    setDailyRoi(res.dailyroi);
    setMatchingBonus(res.matchingincome);
    setMatchingBonusUSDT(res.matchingincomeusdt);
    setMyTotalDeposit(res.usertotaldeposit);
    setJoinDate(res.joindate);
    setNumberOfDeposits(res.numofdeposits);
    setTotalUsers(res.totalusers);
    // setTeamCount(res.teamcount);
    setDirectCount(res.directreferrals);
  };
  const ActReg = async () => {
    if (!account) {
      errorToast("Web3 not connected");
      return;
    }
    let sponsor = sponsorAddress;
    if (!sponsor) {
      const { value: spaddress } = await Swal.fire({
        title: "Sponsor Address",
        input: "text",
        inputLabel: "Please enter your sponsor address",
        inputValidator: (value) => {
          if (!value) {
            return "Please enter valid address";
          }
        },
      });
      if (spaddress) {
        sponsor = spaddress;
      } else {
        return;
      }
    }
    // const { value: amount } = await Swal.fire({
    //   title: "Package Amount",
    //   text: "Min Package Amount is 25 USDT",
    //   input: "text",
    //   inputLabel: "Please enter the amount you want to invest",
    //   showCancelButton: true,
    //   cancelButtonText: "Cancel",
    //   confirmButtonText: "Confirm",
    //   inputValidator: (value) => {
    //     if (!value) {
    //       return "Please enter amount";
    //     } else if (!Number(value)) {
    //       return "Please enter valid amount";
    //     } else if (parseFloat(value) < 25) {
    //       return "Package Amount Must be Min of 25 USDT";
    //     }
    //   },
    // });
    // if (!amount) {
    //   return;
    // }
    // try {
    // let approve = await tokenContract.approve(
    //   ContractAddress,
    //   amount * 10 ** 18
    // );
    // let approvetx = await approve.wait();
    // if (approvetx.transactionHash) {
    //   let sendAmount = await contract.deposit(sponsor, amount * 10 ** 18, {
    //     value: "0x0",
    //   });
    //   let receipt = await sendAmount.wait();
    let res = await ApiRequest(
      "actReg.asp",
      "POST",
      JSON.stringify({
        sponsorid: sponsor,
        // hash: receipt.transactionHash,
        // amount: amount,
      }),
      { Authorization: account }
    );
    if (res.status === "error") {
      errorToast(res.message);
      return;
    }
    successToast(res.message);
    GetDashboardDetails();
    // }
    // } catch (e) {
    //   errorToast(e.message);
    // }
  };
  const BuyContract = async () => {
    if (!account) {
      errorToast("Web3 not connected");
      return;
    }
    if (!newDepositAmount) {
      errorToast("Please enter valid deposit amount");
      return;
    }
    let amount = parseFloat(newDepositAmount);
    if (amount < 50) {
      errorToast("Min Staking Amount is $50");
      return;
    }
    try {
      let div = createLoader();
      document.getElementById("apiloader").innerHTML = div;
      document.getElementById("apiloader").classList.add("loaderDiv");
      let amountInUnits = parseFloat(amount) * 10 ** 18;
      let approve = await tokenContract.approve(
        ContractAddress,
        BigInt(amountInUnits)
      );
      let approvetx = await approve.wait();
      if (approvetx.hash) {
        let sendAmount = await contract.deposit(account, BigInt(amountInUnits));
        let receipt = await sendAmount.wait();
        let res = await ApiRequest(
          "buycontract.asp",
          "POST",
          JSON.stringify({
            package: newDepositAmount,
            hash: receipt.hash,
          }),
          { Authorization: account }
        );
        if (res.status === "error") {
          errorToast(res.message);
          document.getElementById("apiloader").innerHTML = "";
          document.getElementById("apiloader").classList.remove("loaderDiv");
          return;
        }
        successToast(res.message);
        setNewDepositAmount("");
        await Swal.fire({
          title: "Transaction Completed",
          text: "Please allow 2-5 minutes for your transaction to be processed and confirmed on the blockchain. Thank you for your patience.",
          icon: "success",
        });
        timeouts.push(
          setTimeout(() => {
            GetDashboardDetails();
          }, 180000)
        );
      }
      document.getElementById("apiloader").innerHTML = "";
      document.getElementById("apiloader").classList.remove("loaderDiv");
    } catch (e) {
      document.getElementById("apiloader").innerHTML = "";
      document.getElementById("apiloader").classList.remove("loaderDiv");
      let msg = e.reason
        ? e.reason
        : e.data
        ? e.data.message
        : e.message
        ? e.message
        : e;
      errorToast(msg);
    }
  };

  const OnWithdraw = async () => {
    if (!account) {
      errorToast("Web3 not connected");
      return;
    }
    let res = await ApiRequest(
      "WidthdrawalRequest.asp",
      "POST",
      JSON.stringify({ withdrawmode: withdrawmode }),
      { Authorization: account }
    );
    if (res.status === "error") {
      errorToast(res.message);
      return;
    }
    successToast(res.message);
    GetDashboardDetails();
  };
  return (
    <div className="container">
      {showModal && (
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: "block", background: "#f0f8ff70" }}
        >
          <div className="modal-dialog  modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Tree View</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => setShowmodal(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <SponsorTree accounttoken={account} />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="hor-content">
        <div className="page-header mb-5">
          <h4 className="page-title">Welcome To Maxim</h4>
          <div className="d-flex ms-auto ml-auto">
            {account && (
              <a
                href={`https://bscscan.com/address/${account}`}
                target="_blank"
                rel="noreferrer"
                style={{
                  maxWidth: 150,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                className="btn btn-success"
              >
                {account}
              </a>
            )}
            {!account && (
              <button className="btn btn-warning" onClick={connectWallet}>
                Connect Wallet
              </button>
            )}
            {!registeredUser && (
              <button className="btn btn-danger" onClick={ActReg}>
                Register
              </button>
            )}
            {account && registeredUser && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setShowmodal(true)}
              >
                Tree View
              </button>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2 col-sm-6">
            <div className="circle-tile text-white">
              <div className="circle-tile-heading bg-gradient-primary">
                <i className="fas fa-inbox fa-fw fa-2x"></i>
              </div>

              <div className="circle-tile-content bg-gradient-primary">
                <div className="circle-tile-description">MXM Rate</div>
                <div className="circle-tile-number">
                  <AnimatedNumber value={rate} formatValue={4} />
                </div>
                {/* <a className="circle-tile-footer" href="#">More Info <i className="fa fa-chevron-circle-right"></i></a> */}
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-6">
            <div className="circle-tile text-white">
              <div className="circle-tile-heading bg-gradient-info">
                <i className="fas fa-lock fa-fw fa-2x"></i>
              </div>

              <div className="circle-tile-content bg-gradient-info">
                <div className="circle-tile-description"> Total Staked </div>
                <div className="circle-tile-number">
                  <AnimatedNumber value={myTotalDeposit} formatValue={2} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-6">
            <div className="circle-tile text-white">
              <div className="circle-tile-heading bg-gradient-success">
                <i className="fas fa-wallet fa-fw fa-2x"></i>
              </div>

              <div className="circle-tile-content bg-gradient-success">
                <div className="circle-tile-description"> USDT Withdrawn </div>
                <div className="circle-tile-number">
                  <AnimatedNumber value={totalWithdrawUSDT} formatValue={2} />
                </div>
                {/* <a className="circle-tile-footer" href="#">More Info <i className="fa fa-chevron-circle-right"></i></a> */}
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-6">
            <div className="circle-tile text-white">
              <div className="circle-tile-heading bg-gradient-danger">
                <i className="fas fa-download fa-fw fa-2x"></i>
              </div>

              <div className="circle-tile-content bg-gradient-danger">
                <div className="circle-tile-description"> MXM Withdrawn </div>
                <div className="circle-tile-number">
                  <AnimatedNumber value={totalWithdraw} formatValue={2} />
                </div>
                {/* <a className="circle-tile-footer" href="#">More Info <i className="fa fa-chevron-circle-right"></i></a> */}
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-6">
            <div className="circle-tile text-white">
              <div className="circle-tile-heading bg-gradient-default">
                <i className="fas fa-dollar-sign fa-fw fa-2x"></i>
              </div>

              <div className="circle-tile-content bg-gradient-default">
                <div className="circle-tile-description"> USDT Balance </div>
                <div className="circle-tile-number" style={{ fontSize: 16 }}>
                  {usdtBalance}
                </div>
                {/* <a className="circle-tile-footer" href="#">More Info <i className="fa fa-chevron-circle-right"></i></a> */}
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-6">
            <div className="circle-tile text-white">
              <div className="circle-tile-heading bg-gradient-indigo">
                <i className="fas fa-wallet fa-fw fa-2x"></i>
              </div>

              <div className="circle-tile-content bg-gradient-indigo">
                <div className="circle-tile-description"> MXM Balance </div>
                <div className="circle-tile-number">
                  <AnimatedNumber value={tokenBalance} formatValue={2} />
                </div>
                {/* <a className="circle-tile-footer" href="#">More Info <i className="fa fa-chevron-circle-right"></i></a> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row row-deck">
          <div className="col-md-12 col-lg-4">
            <form
              className="card"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="card-body p-6">
                <div className="card-title">New Staking</div>
                <div className="form-group">
                  <label className="form-label">Amount You Want to Stake</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      if (Number(e.target.value) || !e.target.value)
                        setNewDepositAmount(e.target.value);
                    }}
                    id="amount"
                    placeholder="Enter amount"
                    value={newDepositAmount}
                  />
                </div>
                <div className="form-footer">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    onClick={BuyContract}
                  >
                    CONFIRM
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-12 col-lg-4">
            <form className="card">
              <div className="card-body p-6">
                <div className="card-title">Invite Friends</div>
                <div className="form-group">
                  <label className="form-label">Affiliate Link</label>
                  <input
                    readOnly
                    type="text"
                    value={affiliateLink}
                    className="form-control"
                    id="affiliatelink"
                    placeholder="Affiliate link"
                  />
                </div>
                <div className="form-footer">
                  <button
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      copyToClipboard(affiliateLink);
                    }}
                    className="btn btn-primary btn-block"
                  >
                    COPY
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-12 col-lg-4">
            <form
              className="card"
              method="post"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="card-body p-6">
                <div className="card-title">Withdraw</div>
                <div className="form-group">
                  <label className="form-label">Available to Withdraw</label>
                  <input
                    type="number"
                    readOnly
                    disabled
                    value={withdrawmode === "USDT" ? usdtBalance : tokenBalance}
                    className="form-control"
                    placeholder="Withdrawable Amount"
                  />
                </div>
                <div
                  className="form-group d-flex"
                  style={{ alignItems: "center" }}
                >
                  <input
                    type="radio"
                    value="USDT"
                    checked={withdrawmode === "USDT"}
                    onChange={(e) => {
                      setwithdrawmode(e.target.value);
                    }}
                    className="form-radio-control"
                    id="USDT"
                  />
                  <label className="form-label mb-0 ml-1" htmlFor="USDT">
                    USDT
                  </label>
                  {/* <input
                    type="radio"
                    value="MXM"
                    onChange={(e) => {
                      setwithdrawmode(e.target.value);
                    }}
                    checked={withdrawmode === "MXM"}
                    id="MXM"
                    className="form-radio-control  ml-4"
                  />
                  <label className="form-label mb-0 ml-1" htmlFor="MXM">
                    Maxim (MXM)
                  </label> */}
                </div>
                <div className="form-footer">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    onClick={async () => {
                      if (!account) {
                        errorToast("Tronweb not connected");
                        return;
                      }
                      let res = await ApiRequest(
                        "WidthdrawalRequestMsg.asp",
                        "GET",
                        "",
                        { Authorization: account }
                      );
                      if (res.status === "error") {
                        errorToast(res.message);
                        return;
                      }
                      OnWithdraw();
                      //
                    }}
                  >
                    WITHDRAW
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="row row-cards">
          <div className=" col-lg-12">
            <div className="row">
              <div className="col-sm-12 col-lg-3">
                <div className="card">
                  <div className="card-body">
                    <div className="card-value float-right text-purple">
                      <div className="icon icon-shape bg-gradient-danger rounded-circle text-white">
                        <i className="fas fa-dollar-sign text-white"></i>
                      </div>
                    </div>
                    <h3 className="mb-1 text-primary fs-20">
                      <span className=" counter font-30">{totalDeposit}</span>{" "}
                      USDT
                    </h3>
                    <div className="text-muted">Total Token Purchase</div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-3">
                <div className="card">
                  <div className="card-body">
                    <div className="card-value float-right text-purple">
                      <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                        <i className="fas fa-users text-white"></i>
                      </div>
                    </div>
                    <h3 className="mb-1 text-primary counter font-30">
                      {dailyRoi}
                    </h3>
                    <div className="text-muted">APY</div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-3">
                <div className="card">
                  <div className="card-body">
                    <div className="card-value float-right text-purple">
                      <div className="icon icon-shape bg-gradient-orange rounded-circle text-white">
                        <i className="fas fa-edit text-white"></i>
                      </div>
                    </div>
                    <h3 className="mb-1  text-orange counter font-30">
                      {matchingBonusUSDT}
                    </h3>
                    <div className="text-muted">Level Bonus USDT</div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-3">
                <div className="card">
                  <div className="card-body">
                    <div className="card-value float-right text-purple">
                      <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                        <i className="fas fa-coins text-white"></i>
                      </div>
                    </div>
                    <h3 className="mb-1  text-green font-30">
                      <span className="counter">{matchingBonus}</span>
                    </h3>
                    <div className="text-muted">Level Bonus MXM</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-sm-6 col-lg-3">
            <div className="box">
              <div className="icon">
                <div className="image bg-gradient-primary">
                  <i className="fas fa-calendar"></i>
                </div>
                <div className="info card">
                  <h3 className="title">Join Date</h3>
                  <button className="btn text-white center-block bg-gradient-primary">
                    {joinDate}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-xs-12 col-sm-6 col-lg-2">
            <div className="box">
              <div className="icon">
                <div className="image bg-gradient-success">
                  <i className="fas fa-lock"></i>
                </div>
                <div className="info card">
                  <h3 className="title">Total Staked Amount</h3>
                  <button className="btn text-white center-block bg-gradient-success">
                    {myTotalDeposit}
                  </button>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-xs-12 col-sm-6 col-lg-3">
            <div className="box">
              <div className="icon">
                <div className="image bg-gradient-info">
                  <i className="fas fa-user-lock"></i>
                </div>
                <div className="info card">
                  <h3 className="title">Number of Staking</h3>
                  <button className="btn text-white bg-gradient-info center-block">
                    {numberOfDeposits}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-3">
            <div className="box">
              <div className="icon">
                <div className="image bg-gradient-warning">
                  <i className="fas fa-piggy-bank"></i>
                </div>
                <div className="info card">
                  <h3 className="title">Personal Partners</h3>
                  <button className="btn text-white bg-gradient-warning center-block">
                    {directCount}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-xs-12 col-sm-6 col-lg-2">
            <div className="box">
              <div className="icon">
                <div className="image bg-gradient-purple">
                  <i className="fas fa-chess-queen"></i>
                </div>
                <div className="info card">
                  <h3 className="title">Global Members</h3>
                  <button className="btn text-white bg-gradient-purple center-block">
                    {totalUsers}
                  </button>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-xs-12 col-sm-6 col-lg-3">
            <div className="box">
              <div className="icon">
                <div className="image bg-gradient-red">
                  <i className="fas fa-users"></i>
                </div>
                <div className="info card">
                  <h3 className="title">Community Partners</h3>
                  <button className="btn text-white bg-gradient-red center-block">
                    {totalUsers}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
                <div className="col-xs-6 col-md-3">
                  <div className="panel status bg-gradient-primary card">
                    <div className="panel-heading">
                      <h1 className="panel-title text-center">25</h1>
                    </div>
                    <div className="panel-body text-center"> <strong className="text-white">Join Date</strong> </div>
                  </div>
                </div>
                <div className="col-xs-6 col-md-3">
                  <div className="panel status bg-gradient-success card">
                    <div className="panel-heading">
                      <h1 className="panel-title text-center">17</h1>
                    </div>
                    <div className="panel-body text-center"> <strong className="text-white">Total Staking Amount</strong> </div>
                  </div>
                </div>
                <div className="col-xs-6 col-md-3">
                  <div className="panel status bg-gradient-danger card">
                    <div className="panel-heading">
                      <h1 className="panel-title text-center">2</h1>
                    </div>
                    <div className="panel-body text-center"> <strong className="text-white">Total Received Amount </strong> </div>
                  </div>
                </div>
                <div className="col-xs-6 col-md-3">
                  <div className="panel status bg-gradient-info card">
                    <div className="panel-heading">
                      <h1 className="panel-title text-center">18</h1>
                    </div>
                    <div className="panel-body text-center"> <strong className="text-white">Number of Staking</strong> </div>
                  </div>
                </div>
              </div> */}
        <div className="row mb-3">
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
            <div
              className="offer offer-radius offer-default"
              style={{ height: "100%" }}
            >
              <div className="shape"></div>
              <div className="offer-content">
                <h3 className="lead">
                  Maxim Academy Constantly Growing Earnings
                </h3>
                <p>
                  New deposit must be made equal or greater to continue
                  receiving from the fund.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
            <div
              className="offer offer-radius  offer-success"
              style={{ height: "100%" }}
            >
              <div className="shape"></div>
              <div className="offer-content">
                <h3 className="lead text-success"> Scam is impossible! </h3>
                <p>
                  Our technology ensures full safety of all participants funds.
                  Nobody can steal funds, or change functions.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
            <div
              className="offer offer-radius offer-danger"
              style={{ height: "100%" }}
            >
              <div className="shape"></div>
              <div className="offer-content">
                <h3 className="lead  text-danger">
                  Maxim Academy Worldwide legal company with professional team
                </h3>
                <p>
                  We are the officially registered company with team of trusted
                  professionals on blockchain market. We are working 24/7 to
                  increase platform popularity and make it truly worldwide. Join
                  us and get your profit!
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-md-4">
            <div className="dash-box dash-box-color-1">
              <div className="dash-box-icon">
                <i className="fas fa-piggy-bank fa-2x"></i>
              </div>
              <div className="dash-box-body">
                <div className="h3 m-0 text-white">
                  <b>
                    <AnimatedNumber value={directCount} formatValue={0} />
                  </b>
                </div>
              </div>
              <div className="dash-box-action">
                <button className="btn btn-d">Personal Partners</button>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="dash-box dash-box-color-2">
              <div className="dash-box-icon">
                <i className="fas fa-chess-queen fa-2x"></i>
              </div>
              <div className="dash-box-body">
                <div className="h3 m-0 text-white">
                  <b>
                    <AnimatedNumber value={totalUsers} formatValue={0} />
                  </b>
                </div>
              </div>
              <div className="dash-box-action">
                <button className="btn btn-d">Total Members</button>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="dash-box dash-box-color-3">
              <div className="dash-box-icon">
                <i className="fas fa-users fa-2x"></i>
              </div>
              <div className="dash-box-body">
                <div className="h3 m-0 text-white">
                  <b>
                    <AnimatedNumber value={teamCount} formatValue={0} />
                  </b>
                </div>
              </div>
              <div className="dash-box-action">
                <button className="btn btn-d">Community Partners</button>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
